/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  border: "1px solid #000";
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-input {
  outline: none !important;
  box-shadow: none !important;
}

.custom-input:hover,
.custom-input:focus {
  border: none;
  outline: none !important;
  box-shadow: none !important;
  border-bottom: "2px solid #ccc";
}

.custom-tabs .ant-tabs-ink-bar {
  background-color: #cc6a4c;
}

.ag-cell {
  border-right: 1px solid #ccc; /* Right border for cells */
  border-bottom: 1px solid #ccc; /* Bottom border for cells */
}

.ag-header-cell {
  border-right: 1px solid #ccc; /* Right border for header cells */
  border-bottom: 2px solid #333; /* Thicker border for header */
}
