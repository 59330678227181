.sidebar {
    transition: width 0.2s ease-in-out;
  }
  
  .logo {
    height: 32px;
    margin: 16px;
    /* background: rgba(255, 255, 255, 0.3); */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
  }
  
  .sidebar-menu {
    margin-top: 20px;
  }
  
  .toggle-btn {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    color: #fff;
  }
  

  .menuItemSelected {
    background-color: #CC6A4C !important;
    color: white !important;
  }
  
  .menuItem {
    border-radius: 8px;
    color: #CC6A4C !important;
  }
  
  .menuItemHover {
    background-color: #CC6A4C !important;
    color: white !important;
  }

  .submenu-active {
    color: #ffa500 !important;
  }